<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>Editar cliente</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <div class="p-6">
        <!-- Type document -->
        <div>
          <label class="text-sm opacity-75">Tipo de documento*</label>
          <v-select :options="typesDocuments"
                    :clearable="false"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="document.typeDocument"
                    class="mb-4 md:mb-0"
                    name="typeDocument"
                    id="typeDocument"
                    v-validate="'required'"/>
          <span class="text-danger text-sm"
                v-show="errors.has('typeDocument')">{{ errors.first('typeDocument') }}</span>
        </div>
        <!--End-->

        <!--Document-->
        <div class="mt-5">
        <vs-input label="Documento*"
                  v-model.number="document.document"
                  class="w-full" name="document"
                  v-validate="'required'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('document')">{{ errors.first('document') }}</span>
        </div>
        <!--End-->

        <!--Business name-->
        <div class="mt-5">
        <vs-input @blur="document.businessName= trimString(document.businessName)" label="Denominación*"
                  v-model="document.businessName"
                  class="w-full" name="businessName"
                  v-validate="'required'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('businessName')">{{ errors.first('businessName') }}</span>
        </div>
        <!--End-->

        <!--Address-->
        <div class="mt-5">
        <vs-input @blur="document.address= trimString(document.address)" label="Dirección*"
                  v-model="document.address"
                  class="w-full" name="address"
                  v-validate="'required'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('address')">{{ errors.first('address') }}</span>
        </div>
        <!--End-->

        <!--District-->
        <div class="mt-5">
        <vs-input @blur="document.district= trimString(document.district)" label="Distrito*"
                  v-model="document.district"
                  class="w-full mt-5" name="district"
                  v-validate="'required'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('district')">{{ errors.first('district') }}</span>
        </div>
        <!--End-->

        <!--Phone-->
        <div class="mt-5">
        <vs-input @blur="document.phone= trimString(document.phone)" label="Telefono*"
                  v-model="document.phone"
                  class="w-full" name="phone"
                  v-validate="'required'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('phone')">{{ errors.first('phone') }}</span>
        </div>
        <!--End-->

        <!--Email-->
        <div class="mt-5">
        <vs-input @blur="document.email= trimString(document.email)" label="Email*"
                  v-model="document.email"
                  class="w-full" name="email"
                  v-validate="'required'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('email')">{{ errors.first('email') }}</span>
        </div>
        <!--End-->

        <!--Limit-->
        <div class="mt-5">
        <vs-input label="Hora límite*"
                  v-model.number="document.hourLimit"
                  class="w-full"
                  name="hourLimit"
                  type="number"
                  v-validate="'required'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('hourLimit')">{{ errors.first('hourLimit') }}</span>
        </div>
        <!--End-->

        <!--Bank accounts-->
        <div class="mt-5">
          <vs-textarea @blur="document.accounts= trimString(document.accounts)"
                       label="Cuentas*"
                       v-model="document.accounts"
                       class="w-full" name="accounts"
                       rows="7"
                       v-validate="'required'"/>
          <span class="text-danger text-sm"
                v-show="errors.has('accounts')">{{ errors.first('accounts') }}</span>
        </div>
        <!--End-->


        <!---Payment method--->
        <div class="mt-5">
          <label class="text-sm opacity-75">Forma de pago*</label>
          <v-select :options="paymentMethods"
                    label="value"
                    :clearable="false"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="document.paymentMethod" class="mb-4 md:mb-0" name="paymentMethod" id="paymentMethod"
                    v-validate="'required'"/>
          <span class="text-danger text-sm"
                v-show="errors.has('paymentMethod')">{{ errors.first('paymentMethod') }}</span>
        </div>
        <!--End-->
      </div>
    </component>
    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid || progress">Guardar</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
      <div v-if="progress" style="margin-top: 1rem; width: 100%">
        <vs-progress indeterminate color="primary"></vs-progress>
      </div>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import trimString from '../../mixins/trimString'

import { auth, db, FieldValue } from '@/firebase/firebaseConfig'

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      required: true
    }
  },
  components: {
    VuePerfectScrollbar
  },
  mixins: [trimString],
  data () {
    return {
      document: {},
      typesDocuments: [
        'DNI',
        'RUC'
      ],
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      },
      progress: false,
      paymentMethods: [
        {
          id: 7,
          value: 'Crédito'
        },
        {
          id: 0,
          value: 'Contado'
        }
      ]
    }
  },
  watch: {
    isSidebarActive (val) {
      if (val) {
        this.document = {
          ...this.data
        }
        this.$validator.reset()
      }
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get () {
        return this.isSidebarActive
      },
      set (val) {
        if (!val) {
          this.$emit('closeSidebar')
        }
      }
    },
    isFormValid () {
      return !this.errors.any()
    },
    scrollbarTag () {
      return this.$store.getters.scrollbarTag
    }
  },
  methods: {
    /**
     * Save data in firestore
     * @returns {Promise<void>}
     */
    async submitData () {
      try {
        const result = await this.$validator.validateAll()
        if (result) {
          this.progress = true
          // Update client in firestore
          let obj = {
            ...this.document
          }
          await db.collection('clients').doc(this.document.id).update({
            ...obj,
            uid: auth.currentUser.uid,
            updatedAt: FieldValue.serverTimestamp()
          })
          this.$emit('closeSidebar')
          this.$emit('update', obj)
          this.$vs.notify({
            color: 'success',
            title: 'Cliente',
            text: 'Cliente actualizado correctamente.'
          })
        }
      } catch (e) {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error'
        })
      } finally {
        this.progress = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
