<template>
  <div id="data-list-list-view" class="data-list-container">

    <div v-if="initProgress" class="w-100 mt-5 mb-5">
      <vs-progress indeterminate color="primary"></vs-progress>
    </div>
    <vs-prompt title="Exportar a Excel" class="export-options" @cancle="clearFields" @accept="exportToExcelOrders"
               accept-text="Exportar" cancel-text="Cancelar" @close="clearFields" :active.sync="activePromptOrders">
      <vs-input v-model="fileName" placeholder="Ingresa el nombre del archivo..." class="w-full"/>
      <v-select v-model="selectedFormat" :options="formats" class="my-4"/>
      <div class="flex">
        <span class="mr-4">Auto ajustar columna:</span>
        <vs-switch v-model="cellAutoWidth">Auto ajustar columna</vs-switch>
      </div>
    </vs-prompt>

    <vx-card collapse="false" ref="filterCard" title="Filtros" class="user-list-filters mb-8" collapse-action
             refresh-content-action @refresh="resetFilters">
      <h5 class="mt-5 mb-2">Filtrar por fecha</h5>
      <div class="vx-row">
        <div class="vx-col md:w-1/2">
          <flat-pickr :config="configdateTimePicker"
                      v-model="filterData.startDate"
                      placeholder="Desde"
                      class="w-full"/>
        </div>
        <div class="vx-col md:w-1/2">
          <flat-pickr :config="configdateTimePicker"
                      v-model="filterData.endDate"
                      placeholder="Hasta"
                      class="w-full"/>
        </div>
        <div class="vx-col mt-2">
          <vs-button @click="filter('dates')" class="mr-6 vs-align-right">Filtrar</vs-button>
        </div>
      </div>
    </vx-card>

    <vs-table v-model="selected" ref="table" pagination :max-items="itemsPerPage" search
              :data="list">

      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
        <div class="flex flex-wrap-reverse items-center data-list-btn-container">
          <!-- Action - dropdown -->
          <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer mr-4">
            <div
                class="p-4 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center text-lg font-medium w-full">
              <span class="mr-2">Acciones</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item @click="activePromptOrders=true">
                            <span class="flex items-center">
                              <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Exportar</span>
                            </span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>

        <!-- Items per page -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4 items-per-page-handler">
          <div
              class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
            <span class="mr-2">{{
                currentPage * itemsPerPage - (itemsPerPage - 1)
              }} - {{ list.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : list.length }} de {{
                queriedItems
              }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
          </div>
          <vs-dropdown-menu>

            <vs-dropdown-item @click="itemsPerPage=10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=20">
              <span>20</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=30">
              <span>30</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=40">
              <span>40</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th sort-key="number">Id</vs-th>
        <vs-th sort-key="number">Tienda</vs-th>
        <vs-th sort-key="date">Fecha de recojo</vs-th>
        <vs-th sort-key="subTotal">Fecha de entrega</vs-th>
        <vs-th sort-key="date">Fecha de creación</vs-th>
        <vs-th sort-key="shipping">Costo de envío</vs-th>
        <vs-th sort-key="clientDocumento">Dirección</vs-th>
        <vs-th sort-key="clientDocumento">Distrito</vs-th>
        <vs-th sort-key="state">Estado</vs-th>
      </template>

      <template slot-scope="{data}">
        <tbody>
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

          <vs-td>
            <p class="product-name font-medium truncate">{{ tr.id.substr(0, 6).toUpperCase() }}</p>
          </vs-td>

          <vs-td>
            <p class="product-name font-medium truncate">{{ tr.client.name }}</p>
          </vs-td>

          <vs-td>
            <p class="product-name font-medium truncate"> {{ tr.pickUpDate }}</p>
          </vs-td>

          <vs-td>
            <p class="product-name font-medium truncate"> {{ tr.deadline }}</p>
          </vs-td>

          <vs-td>
            <p class="product-name font-medium truncate">{{ tr.createdAt }}</p>
          </vs-td>

          <vs-td>
            <p class="product-name font-medium truncate"> S/ {{ tr.district.price + tr.type.price }}</p>
          </vs-td>

          <vs-td>
            <p class="product-name font-medium truncate"> {{ tr.address }}</p>
          </vs-td>

          <vs-td>
            <p class="product-name font-medium truncate"> {{ tr.district.label }}</p>
          </vs-td>

          <vs-td>
            <vs-chip :color="getOrderStatusColor(tr.amountsMatch)" class="product-order-status">
              {{ getStateText(tr.amountsMatch) | title }}
            </vs-chip>
          </vs-td>
        </vs-tr>
        </tbody>
      </template>
    </vs-table>
  </div>
</template>

<script>
import moment from "moment"

import vSelect from 'vue-select'
import { db, Timestamp } from '@/firebase/firebaseConfig'

import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

let _ = require('lodash')

export default {
  components: {
    vSelect,
    flatPickr
  },
  data () {
    return {
      selected: null,
      itemsPerPage: 20,
      isMounted: false,
      list: [],
      initProgress: false,

      //Filters
      showFilter: false,

      filterData: {
        startDate: moment(new Date()).format('YYYY-MM-DD'),
        endDate: moment(new Date()).format('YYYY-MM-DD'),
      },

      // Date
      configdateTimePicker: {
        dateFormat: 'Y-m-d'
      },

      // Data Sidebar
      newSidebar: false,
      updateSidebar: false,
      featureSideBar: false,
      sidebarData: {},

      // Export
      fileName: '',
      formats: ['xlsx', 'csv', 'txt'],
      cellAutoWidth: true,
      selectedFormat: 'xlsx',
      activePrompt: false,
      activePromptOrders: false
    }
  },
  async created () {
    await this.filter('today')
  },
  mounted () {
    this.isMounted = true
  },
  computed: {
    currentPage () {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    queriedItems () {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.list.length
    }
  },
  methods: {
    /**
     * Filter
     * @param type
     * @returns {Promise<void>}
     */
    async filter (type) {
      try {
        this.initProgress = true
        this.list = []
        // Set start date
        let startDate = new Date()
        let endDate = new Date()
        startDate.setHours(0)
        startDate.setMinutes(0)
        startDate.setSeconds(0)
        // Set end date
        endDate.setHours(23)
        endDate.setMinutes(59)
        endDate.setSeconds(0)

        let startTimes = null
        let endTimes = null

        if (type === 'today') {
          startTimes = Timestamp.fromDate(startDate)
          endTimes = Timestamp.fromDate(endDate)
        }
        else if (type === 'tomorrow') {
          startDate.setDate((new Date()).getDate() + 1)
          endDate.setDate((new Date()).getDate() + 1)
          startTimes = Timestamp.fromDate(startDate)
          endTimes = Timestamp.fromDate(endDate)
        }
        else if (type === 'dates') {
          startDate = new Date(this.filterData.startDate + ' 05:00:00')
          startDate.setHours(0)
          startDate.setMinutes(0)
          startDate.setSeconds(0)
          console.log(startDate)
          endDate = new Date(this.filterData.endDate + ' 05:00:00')
          endDate.setHours(23)
          endDate.setMinutes(59)
          endDate.setSeconds(0)
          console.log(endDate)

          startTimes = Timestamp.fromDate(startDate)
          endTimes = Timestamp.fromDate(endDate)
        }

        const querySnapshot = await db.collection('detail')
            .where('deadline', '>=', startTimes)
            .where('deadline', '<=', endTimes)
            .orderBy('deadline', 'asc')
            .get()

        querySnapshot.forEach((doc) => {
          const obj = {
            ...doc.data(),
            pickUpDate: doc.data().pickUpDate.toDate(),
            deadline: doc.data().deadline.toDate(),
            createdAt: doc.data().createdAt.toDate()
          }

          obj.id = doc.id

          obj.pickUpDate = moment(obj.pickUpDate).format('YYYY-MM-DD')
          obj.deadline = moment(obj.deadline).format('YYYY-MM-DD')
          obj.createdAt = moment(obj.createdAt).format('YYYY-MM-DD HH:mm')

          this.list.push({
            ...obj
          })
        })
      } catch (e) {
        console.log(e)
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      } finally {
        this.initProgress = false
      }
    },
    /**
     * Reset filters
     * @returns {Promise<void>}
     */
    async resetFilters () {
      try {
        this.initProgress = true
        await this.filter('today')

        this.$refs.filterCard.removeRefreshAnimation()
      } catch (e) {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      } finally {
        this.initProgress = false
      }
    },
    /**
     * Get order status color
     * @param status
     * @returns {string}
     */
    getOrderStatusColor (status) {
      if (status) return 'dark'
      else return 'danger'
    },
    /**
     * Get state text
     * @param value
     * @returns {string}
     */
    getStateText (value) {
      if (value) return 'Validado'
      else return 'Por validar'
    },
    exportToExcelOrders () {
      try {
        this.initProgress = true
        let list = _.cloneDeep(this.list)

        let headerTitle = ['N° DE ORDEN', 'TIENDA', 'FECHA DE RECOJO', 'FECHA DE ENTREGA', 'TIPO DE SERVICIO', 'DIRECCION DE ENTREGA Y REFERENCIA', 'DISTRITO', 'UBICACION URL (OPCIONAL)', 'DESTINATARIO', 'TELÉFONO', 'COSTO DE ENVIO S/.', 'DESCRIPCION PRODUCTO', 'CANTIDAD', 'NOTAS ENTREGA']
        let headerVal = ['index', 'client', 'pickUpDate', 'deadline', 'type', 'address', 'district', 'location', 'fullName', 'phone', 'price', 'product', 'quantity', 'notes']
        import('@/vendor/Export2Excel').then(async excel => {
          list.forEach((obj, index) => {
            obj.index = index + 1
            obj.client = obj.client.name
            obj.type = obj.type.label
            obj.district = obj.district.label
            obj.price = obj.district.price + obj.type.price
            obj.address = obj.address + '/' + obj.reference
            console.log(obj)
          })
          const data = this.formatJson(headerVal, list)
          excel.export_json_to_excel({
            header: headerTitle,
            data,
            filename: this.fileName,
            autoWidth: this.cellAutoWidth,
            bookType: this.selectedFormat
          })
          this.clearFields()
          this.initProgress = false
        })
      } catch (e) {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
        this.initProgress = false
      }
    },
    /**
     * Clear fields
     */
    clearFields () {
      this.filename = ''
      this.cellAutoWidth = true
      this.selectedFormat = 'xlsx'
    },
    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        // Add col name which needs to be translated
        // if (j === 'timestamp') {
        //   return parseTime(v[j])
        // } else {
        //   return v[j]
        // }

        return v[j]
      }))
    }
  }
}
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {

    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);

        td {
          padding: 20px;

          &:first-child {
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }

          &:last-child {
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
