<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>Editar usuario</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">

      <div class="p-6">

        <!-- Display Name -->
        <vs-input @blur="user.displayName= trimString(user.displayName)" label="Nombre completo"
                  v-model="user.displayName"
                  class="w-full" name="name"
                  v-validate="'required'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('name')">{{ errors.first('name') }}</span>

        <!-- Phone -->
        <vs-input @blur="user.phone= trimString(user.phone)" label="Celular*" v-model.number="user.phone"
                  class="mt-5 w-full" name="email"
                  v-validate="'required|min:6|max:50'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('phone')">{{ errors.first('phone') }}</span>

        <!-- Email -->
        <vs-input @blur="user.email= trimString(user.email)" label="Email*" v-model.number="user.email"
                  class="mt-5 w-full" name="email"
                  v-validate="{required: true, email: true}"/>
        <span class="text-danger text-sm"
              v-show="errors.has('email')">{{ errors.first('email') }}</span>

        <!--Roles-->
        <div class="mt-5">
          <label class="text-sm opacity-75">Rol*</label>
          <v-select :options="roles" :clearable="false"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="user.role" class="mb-4 md:mb-0" name="role"
                    v-validate="'required'"/>

          <span class="text-danger text-sm"
                v-show="errors.has('role')">{{ errors.first('role') }}</span>
        </div>
        <!--End-->

        <!--Clients-->
        <div v-if="user.role && user.role.value === 'client'" class="mt-5">
          <label class="text-sm opacity-75">Cliente*</label>
          <v-select :options="clients"
                    label="businessName"
                    :clearable="false"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="user.client"
                    class="mb-4 md:mb-0"
                    name="client"
                    v-validate="'required'"/>
          <span class="text-danger text-sm"
                v-show="errors.has('client')">{{ errors.first('client') }}</span>
        </div>
        <!--End-->

        <!---State-->
        <div class="mt-5" v-if="user.role.value === 'client'">
          <vs-switch v-model="user.state"/>
        </div>
        <!--End-->
      </div>
    </component>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid || progress">Actualizar</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
      <div v-if="progress" style="margin-top: 1rem; width: 100%">
        <vs-progress indeterminate color="primary"></vs-progress>
      </div>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Datepicker from 'vuejs-datepicker'
import trimString from '../../mixins/trimString'

import { auth, db, FieldValue } from '@/firebase/firebaseConfig'

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {
      }
    },
    clients: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  components: {
    VuePerfectScrollbar,
    Datepicker
  },
  mixins: [trimString],
  data () {
    return {
      user: {
        displayName: null,
        email: null,
        role: null
      },
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      },
      progress: false,
      roles: [
        { value: 'admin', label: 'Admin' },
        { value: 'editor', label: 'Operario' },
        { value: 'client', label: 'Cliente' },
      ]
    }
  },
  watch: {
    isSidebarActive (val) {
      if (val) {
        this.user = {
          ...this.data
        }
      }
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get () {
        return this.isSidebarActive
      },
      set (val) {
        if (!val) {
          this.$emit('closeSidebar')
        }
      }
    },
    isFormValid () {
      return !this.errors.any()
    },
    scrollbarTag () {
      return this.$store.getters.scrollbarTag
    }
  },
  methods: {
    /**
     * Save data
     */
    submitData () {
      this.$validator.validateAll().then(async result => {
        if (result) {
          try {
            this.progress = true
            // Update in Auth
            let token = await auth.currentUser.getIdTokenResult()
            await this.$http.post('https://southamerica-east1-enwam-8794a.cloudfunctions.net/user-updateUser', {
              uid: this.user.uid,
              ...this.user,
              idToken: token.token
            })
            //Update in Firestore
            let userRef = db.collection('users').doc(this.user.id)

            let cloneUser = Object.assign({}, this.user)
            delete cloneUser.id
            delete cloneUser.password
            await userRef.update({
              ...cloneUser,
              updatedAt: FieldValue.serverTimestamp()
            })
            //
            this.progress = false
            let obj = {
              ...this.user
            }
            await this.$store.dispatch('users/updateUser', { user: obj })
            this.$emit('closeSidebar')

            this.$vs.notify({
              color: 'success',
              title: 'Usuario',
              text: 'Usuario actualizado correctamente.'
            })
          } catch (e) {
            this.progress = false
            this.$vs.notify({
              color: 'warning',
              title: '¡Oh no!',
              text: 'Hubo un error.'
            })
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
