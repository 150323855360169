export default [
  {
    header: 'Clients',
    icon: 'SmileIcon',
    i18n: 'Clients',
    items: [
      {
        url: '/clients/list',
        name: 'List',
        slug: 'clients',
        icon: 'SmileIcon',
        i18n: 'List'
      },
      {
        url: '/clients/addresses',
        name: 'ClientsAddresses',
        slug: 'addresses',
        icon: 'MapIcon',
        i18n: 'Addresses'
      },
      {
        url: '/clients/contacts',
        name: 'ClientsContact',
        slug: 'contacts',
        icon: 'BookIcon',
        i18n: 'Contacts'
      }
    ]
  },
  {
    header: 'Settings',
    icon: 'SettingsIcon',
    i18n: 'Settings',
    items: [
      {
        url: '/settings/users',
        name: 'Users',
        slug: 'users',
        icon: 'SettingsIcon',
        i18n: 'Users'
      },
      {
        url: '/settings/content',
        name: 'General',
        slug: 'content',
        icon: 'AlignCenterIcon',
        i18n: 'General'
      }
    ]
  },
  {
    header: 'Orders',
    icon: 'CartIcon',
    i18n: 'Orders',
    items: [
      {
        url: '/orders/shipments',
        name: 'Shipments',
        slug: 'shipments',
        icon: 'TruckIcon',
        i18n: 'Shipments'
      },
      {
        url: '/orders/pickups',
        name: 'Pickups',
        slug: 'pickups',
        icon: 'TruckIcon',
        i18n: 'Pickups'
      },
      {
        url: '/orders/list',
        name: 'Orders',
        slug: 'orders',
        icon: 'FileIcon',
        i18n: 'Orders'
      }
    ]
  }
]

