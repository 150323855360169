<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>Agregar dirección</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <div class="p-6">
        <!-- Clients -->
        <template v-if="clients.length>0">
          <div>
            <label class="text-sm opacity-75">Cliente*</label>
            <v-select placeholder="Selecciona" :options="clients"
                      :clearable="false"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      v-model="document.client" class="mb-4 md:mb-0" name="client" id="client"
                      label="businessName"
                      v-validate="'required'"/>
            <span class="text-danger text-sm"
                  v-show="errors.has('client')">{{ errors.first('client') }}</span>
          </div>
        </template>
        <template v-else>
          <vs-input label="Cliente*"
                    v-model="document.client.businessName"
                    class="w-full" name="client"
                    disabled
                    v-validate="'required'"/>
        </template>

        <!--Address-->
        <vs-input @blur="document.address= trimString(document.address)" label="Dirección*"
                  v-model="document.address"
                  class="w-full mt-5" name="address"
                  v-validate="'required'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('address')">{{ errors.first('address') }}</span>

        <!-- Districts -->
        <div class="mt-5">
          <label class="text-sm opacity-75">Distrito*</label>
          <v-select placeholder="Selecciona" v-validate="'required'" label="nomDistrito"
                    :options="districts"
                    :clearable="false"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="document.district" class="mb-4 md:mb-0" name="district"/>
          <span class="text-danger text-sm"
                v-show="errors.has('district')">{{ errors.first('district') }}</span>
        </div>
        <!--End--->
      </div>
    </component>
    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid || progress">Guardar</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
      <div v-if="progress" style="margin-top: 1rem; width: 100%">
        <vs-progress indeterminate color="primary"></vs-progress>
      </div>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import trimString from '../../../mixins/trimString'

import { auth, db, FieldValue } from '@/firebase/firebaseConfig'

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    clients: {
      type: Array,
      required: true
    },
    districts: {
      type: Array,
      required: true
    },
    client: {
      type: Object
    }
  },
  components: {
    VuePerfectScrollbar
  },
  mixins: [trimString],
  data () {
    return {
      document: {
        department: {},
        province: {},
        district: {},
        client: {}
      },
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      },
      progress: false
    }
  },
  watch: {
    isSidebarActive (val) {
      if (val) {
        this.document = {
          department: null,
          province: null,
          district: null,
          client: null
        }
        if (this.client) {
          this.document.client = {
            ...this.client
          }
        }
        this.$validator.reset()
      }
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get () {
        return this.isSidebarActive
      },
      set (val) {
        if (!val) {
          this.$emit('closeSidebar')
        }
      }
    },
    isFormValid () {
      return !this.errors.any()
    },
    scrollbarTag () {
      return this.$store.getters.scrollbarTag
    }
  },
  methods: {
    /**
     * Save data in firestore
     * @returns {Promise<void>}
     */
    async submitData () {
      try {
        const result = await this.$validator.validateAll()
        if (result) {
          this.progress = true
          // New address
          let obj = {
            ...this.document,
            state: true
          }
          const doc = await db.collection('clients').doc(this.document.client.id).collection('addresses').add({
            ...obj,
            uid: auth.currentUser.uid,
            createdAt: FieldValue.serverTimestamp()
          })
          obj.id = doc.id
          this.$emit('closeSidebar')
          this.$emit('add', obj)
          this.$vs.notify({
            color: 'success',
            title: 'Dirección',
            text: 'Dirección creada correctamente.'
          })
        }
      } catch (e) {
        console.log(e)
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error'
        })
      } finally {
        this.progress = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52011;
  }

  ::v-deep .vs-sidebar {
    z-index: 52011;
    width: 450px !important;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
