import Vue from 'vue'
import Router from 'vue-router'

import firebase from 'firebase/app'
import 'firebase/auth'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes: [

    {
      // =============================================================================
      // Main layout routes
      // =============================================================================
      path: '',
      component: () => import('./layouts/main/Main.vue'),
      children: [
        // =============================================================================
        // Routes
        // =============================================================================
        {
          path: '/',
          redirect: '',
          component: () => import('./views/Home'),
          meta: {
            rule: 'admin',
            authRequired: true
          }
        },
        /**End**/
        /**
         * Clients
         */
        {
          path: 'clients/list',
          name: 'clients-list',
          component: () => import('./views/clients/App'),
          meta: {
            rule: 'admin',
            authRequired: true
          }
        },
        {
          path: 'clients/addresses',
          name: 'clients-addresses',
          component: () => import('./views/clients/addresses/App'),
          meta: {
            rule: 'admin',
            authRequired: true
          }
        },
        {
          path: 'clients/contacts',
          name: 'clients-contacts',
          component: () => import('./views/clients/contacts/App'),
          meta: {
            rule: 'admin',
            authRequired: true
          }
        },
        /**End**/
        /**
         * Shipments
         */
        {
          path: 'orders/shipments',
          name: 'orders-shipments',
          component: () => import('./views/shipments/App'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: 'orders/pickups',
          name: 'orders-pickups',
          component: () => import('./views/pickups/App'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: 'orders/list',
          name: 'orders-list',
          component: () => import('./views/orders/App'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        /**
         * Settings
         */
        {
          path: 'settings/users',
          name: 'users',
          component: () => import('./views/users/App'),
          meta: {
            rule: 'admin',
            authRequired: true
          }
        },
        {
          path: 'settings/content',
          name: 'settings',
          component: () => import('./views/Content.vue'),
          meta: {
            rule: 'admin',
            authRequired: true
          }
        },
        {
          path: '/pages/error-404',
          name: 'page-error-404',
          component: () => import('./views/pages/Error404.vue'),
          meta: {
            rule: 'editor'
          }
        }
      ]
    },
    // Full Page Layout
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        {
          path: '/pages/login',
          name: 'page-login',
          component: () => import('@/views/login/Login.vue'),
          meta: {
            rule: 'editor',
          }
        },
        // Redirect to 404 page, if no match found
        {
          path: '*',
          redirect: '/pages/error-404',
          meta: {
            rule: 'editor'
          }
        }
      ]
    }
  ]
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach((to, from, next) => {
  firebase.auth().onAuthStateChanged(() => {

    // get firebase current user
    const firebaseCurrentUser = firebase.auth().currentUser

    // If auth required, check login. If login fails redirect to login page

    if (to.meta.authRequired) {
      if (!(firebaseCurrentUser)) {
        router.push({ path: '/pages/login', query: { to: to.path } })
      }
    }
    return next()
  })
})

export default router
