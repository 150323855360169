import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/analytics'
import 'firebase/storage'

// Initialize Firebase
let config = {
  apiKey: "AIzaSyCuVA8PRaaraDOn8oWckD03OM0XhRWt_6o",
  authDomain: "enwam-8794a.firebaseapp.com",
  projectId: "enwam-8794a",
  storageBucket: "enwam-8794a.appspot.com",
  messagingSenderId: "158664121222",
  appId: "1:158664121222:web:608232683edf03e2b36a07",
  measurementId: "G-BTM81Z0VWE"
}

firebase.initializeApp(config)
firebase.analytics()

// utils
const db = firebase.firestore()
const storage = firebase.storage()
const FieldValue = firebase.firestore.FieldValue
const Timestamp = firebase.firestore.Timestamp
const auth = firebase.auth()

export {
  db,
  auth,
  FieldValue,
  Timestamp,
  storage
}
